<template>
  <main class="page__content--wider">
    <div class="page__head">
      <div
        class="page__head__container align-items-center justify-content-between"
      >
        <div class="page__head__text">
          <h1 class="page__head2__title">Activities</h1>
        </div>
        <div class="page__head__actions">
          <div class="d-flex">

            <div class="align-self-center mx-2 dropdown">
              <button
                class="
                  button button--grey
                  text--capital text--small
                  d-flex
                  align-items-center
                "
                type="button"
                @click="toggleFilterModal"
              >
                <span class="filter_text"> Filter </span>
                <span class="dropdown__icon">
                  <ion-icon name="caret-down-outline"></ion-icon>
                </span>
              </button>
            </div>

            <div class="align-self-center mx-2 dropdown">
              <button
                class="
                  button button--grey
                  text--capital text--small
                  d-flex
                  align-items-center
                "
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                export
                <span class="dropdown__icon">
                  <ion-icon name="caret-down-outline"></ion-icon>
                </span>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a
                  href="javascript:void(0)"
                  v-if="userCanExportToPDF"
                  @click="pdfDownload"
                  class="dropdown-item text--capital"
                  >PDF</a
                >
                <a
                  href="javascript:void(0)"
                  @click="processDownload"
                  v-if="userCanExportToExcel"
                  class="dropdown-item text--capital"
                  >excel</a
                >
                <!-- <download-excel
                  class="dropdown-item text--capital"
                  style="cursor: pointer"
                  :data="download"
                  :fields="json_fields"
                  name="activities.xls"
                >
                  excel
                </download-excel> -->
                <!-- <a class="dropdown-item text--capital" @click="downloadCSVFile"
                  >CSV</a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="display:flex; width:50%; font-size:14px" v-if="filterText">
      <div style="width:80%">
        {{ filterText }}
      </div>
      <div style="display:flex;width:10%">
        <span style="cursor:pointer;font-size:17px" @click="editFilter">
          <i style="padding-right:5px" class="bi bi-pencil-square"></i>
        </span>
        <span style="cursor:pointer;font-size:17px" @click="deleteFilter">
          <i style="padding-left:5px" class="bi bi-x-circle-fill"></i>
        </span>
      </div>
    </div>

    <div class="page__body">
      <table class="customtable w-100">
        <thead>
          <tr>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 15%"
            >
              Creation Date
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 15%"
            >
              User
            </th>
            <!-- <th scope="col" class="table__header__text table__text text--capital" style="width: 10%;">Account ID
            </th> -->
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 15%"
            >
              Action
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 15%"
            >
              Affected Item Name
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 15%"
            >
              Affected Item ID
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 15%"
            >
              Affected Customer Name
            </th>
          </tr>
        </thead>
        <tbody v-if="loadingActivities">
          <tr>
            <td colspan="12">
              <div class="d-flex align-items-center justify-content-center p5">
                <div
                  class="spinner-border"
                  style="width: 3rem; height: 3rem"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
                <span
                  class="text--black text--600 text--medium d-inline-flex ml-4"
                  >Please wait</span
                >
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="allActivities.data && allActivities.data.data != ''">
          <tr v-for="(activity, i) in allActivities.data.data" :key="i">
            <td>
              <span
                class="
                  table__body__text table__text
                  text--capital text--primary
                  cursor
                "
              >
                {{ activity.creationDate | moment }}</span
              >
            </td>
            <td>
              <span class="table__body__text table__text text--capital" style="word-break: break-all;"
                >{{activity.user}}</span
              >
            </td>
            <td>
              <span
                class="
                  table__body__text table__text
                  text--capital
                "
              >
                {{ activity.action }}</span
              >
              <!-- <a href="/" class="table__body__text table__text text--capital text--primary">{{ account.accountFirstName }} {{ account.accountLastName }}</a> -->
            </td>
            <td>
              <span class="table__body__text table__text text--capital">{{
                activity.affectedItemName
              }}</span>
            </td>
            <td>
              <span 
                class="
                  table__body__text table__text text--capital
                "
              >{{
                activity.affectedItemId
              }}</span>
            </td>
            <td>
              <span 
                @click="viewRoute(activity.affectedCustomerNameKey)"
                class="
                  table__body__text table__text
                  text--capital text--primary
                  cursor
                "
              >{{
                activity.affectedCustomerName
              }}</span>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="12">
              <div
                class="
                  d-flex
                  flex-column
                  align-items-center
                  justify-content-center
                  p5
                "
              >
                <img src="@/assets/img/empty.svg" alt="delete" srcset="" />

                <span
                  class="d-block text--danger text--600 text--medium mt-3 mb-5"
                  >No data available</span
                >
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <Pagination
        :allItems="allActivities.data ? allActivities.data : {}"
        :size="size"
        @update-created-task="getUpdatedDeposits"
        @change-page-index="changePageIndex"
      />
    </div>

    <!-- Filter -->
    <CreateFilter
      :show="showFilterModal"
      @close="toggleFilterModal"
      @send-activate-filter="sendFilter"
      @reload="reloadFilter"
      :filterEditValue="filterEdit"
      :showEditedValue="showEditedValue"
      @alertError="alertError"
      :filterHeaderName="'Activities'"
      filterCategory="activity"
    />

    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>

  </main>
</template>

<script>
import {
  GET_ALL_SYSTEM_ACTIVITIES,
  GET_ACTIVITIES_EXPORT_DATA,
} from "@/core/services/store/activity.module";
import { mapState } from "vuex";
import { Role } from "@/core/services/role.js";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
// import JsonExcel from "vue-json-excel";
import Papa from "papaparse";
export default {
  name: "Activities",
  data: function () {
    return {
      pages: 1,
      size: 20,
      showAccountStatusModal: false,
      loading: false,
      account: {
        accountKey: "",
        accountStatus: "",
      },
      fetchAccount: {
        filters: [],
        pageIndex: 1,
        pageSize: 20,
        filterCategory: "",
        isFromFilter: false,
      },
      alert: {
        show: false,
        status: "",
        title: "Success",
        description: "Deposit Product has been created",
      },
      json_fields: {
        "Creation Date": "date",
        User: "user",
        Action: "action",
        "Affected Item Name ": "item_Name",
        "Affected Item ID": "itemID",
        "Affected Customer Name ": "customerName",
      },
      showFilterModal: false,
      filterText: "",
      filterEdit: [],
      showEditedValue: 0,
    };
  },
  components: {
    // downloadExcel: JsonExcel,
    Pagination: () =>
      import(
        /* WebpackChunkName: "Pagination" */ "@/components/UI/pagination.vue"
      ),
    CreateFilter: () =>
      import(
        /* WebpackChunkName: "CreateFilter" */ "@/view/pages/deposit/filter.vue"
      ),
  },
  watch: {
    allActivities() {
      this.filterText = this.allActivities.data.filterText ? this.allActivities.data.filterText.replace(/_/g, " ") : "";
      this.filterEdit = this.allActivities.data.filters ? this.allActivities.data.filters : [];
      if(this.allActivities.data.filterText && this.allActivities.data.filters) {
        this.getExportData();
      }
    }
  },
  methods: {
    toggleFilterModal() {
      this.showFilterModal = !this.showFilterModal;
    },
    sendFilter(value) {
      const filterArray = value.map((obj) => {
        const payload = {};

        payload.filterFieldName = obj.filterName;
        payload.filterFieldOperator = obj.filterOp;
        if (obj.value1 === '' || obj.value1 === null) {
          payload.filterFieldValues = [obj.value];
          return payload;
        }
        payload.filterFieldValues = [obj.value, obj.value1];
        return payload;
      });
      this.fetchAccount.filters = filterArray;
      this.fetchAccount.pageIndex = 1;
      this.fetchAccount.pageSize = 10;
      this.fetchAccount.filterCategory = "activity";
      this.fetchAccount.isFromFilter = true;
      this.$store
        .dispatch(GET_ALL_SYSTEM_ACTIVITIES, this.fetchAccount)
        .then(() => {
          this.fetchAccount.isFromFilter = false,
          this.alertSuccess("Successfully applied filter");
        })
        .catch((error) => {
          this.alertError(error.response.data.message);
        });
    },
    getActivities() {
      this.$store.dispatch(GET_ALL_SYSTEM_ACTIVITIES, this.fetchAccount);
    },
    reloadFilter() {
      this.deleteFilter();
      this.fetchAccount.isFromFilter = false;
      this.fetchAccount.filterCategory = "";
      this.fetchAccount.filters = [];
      this.fetchAccount.pageIndex = 1;
      this.fetchAccount.pageSize = 20;
      this.getActivities();
    },
    getExportData() {
      if (this.allActivities.data.totalCount > 0) {
        let data = this.fetchAccount;
        data.pageSize = this.allActivities.data.totalCount;
        this.$store.dispatch(GET_ACTIVITIES_EXPORT_DATA, data)
      }
    },
    deleteFilter() {
      this.filterText = "";
      this.filterEdit = [];
    },
    editFilter() {
      this.showEditedValue = Math.random();
      this.toggleFilterModal();
    },
    processDownload() {
      let wb = XLSX.utils.book_new();

      wb.Props = {
        Title: "Activities Download",
        Subject: "Activities Excel Sheet",
        Author: "Prolean",
        createdDate: Date.now(),
      };

      wb.SheetNames.push("Activities");

      let sheetData = [[
        "Creation Date",
        "User",
        "Action",
        "Affected Item Name",
        "Affected Item ID",
        "Affected Customer Name ",
      ]];
      if (this.allActivitiesExports && this.allActivitiesExports.data) {
        this.allActivitiesExports.data.map((item) => {
          let new_arr = [];
          new_arr.push(moment(item.creationDate).format("Do-MMM-YYYY"));
          new_arr.push(item.user);
          new_arr.push(item.action);
          new_arr.push(item.affectedItemName);
          new_arr.push(item.affectedItemId);
          new_arr.push(item.affectedCustomerName);

          sheetData.push(new_arr);
        });
      }

      let ws = XLSX.utils.aoa_to_sheet(sheetData);

      wb.Sheets["Activities"] = ws;

      let wbSheet = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

      this.saveExcelSheet(this.convertBinaryToOctetStream(wbSheet));
    },
    convertBinaryToOctetStream(sheet) {
      let buf = new ArrayBuffer(sheet.length);
      let view = new Uint8Array(buf);

      for (let i = 0; i < sheet.length; i++) {
        view[i] = sheet.charCodeAt(i) & 0xff;
      }

      return buf;
    },
    saveExcelSheet(data) {
      const url = URL.createObjectURL(
        new Blob([data], { type: "application/vnd.ms-excel" })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "activities.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    pdfDownload() {
      const columns = [
        { title: "Creation Date", dataKey: "date" },
        { title: "User", dataKey: "user" },
        { title: "Action", dataKey: "action" },
        { title: "Affected Item Name ", dataKey: "item_Name" },
        { title: "Affected Item ID", dataKey: "itemID" },
        { title: "Affected Customer Name", dataKey: "customerName" },
      ];

      let doc = new jsPDF("l", "pt", "a4");
      doc.setFontSize(6);
      doc.autoTable(columns, this.download);
      doc.save("activities.pdf");
    },
    changeCurrentPage(pages = null, pageSize) {
      if (pages === "next") {
        let nextPage = this.allActivities.data.hasNext
          ? this.allActivities.data.currentPage + 1
          : 0;
        this.fetchAccount.pageIndex = nextPage;
        this.fetchAccount.pageSize = pageSize;
        this.$store.dispatch(GET_ALL_SYSTEM_ACTIVITIES, this.fetchAccount);
      } else if (pages === "previous") {
        let nextPage = this.allActivities.data.hasPrevious
          ? this.allActivities.data.currentPage - 1
          : 0;
        this.fetchAccount.pageIndex = nextPage;
        this.fetchAccount.pageSize = pageSize;
        this.$store.dispatch(GET_ALL_SYSTEM_ACTIVITIES, this.fetchAccount);
      } else if (pages !== null) {
        this.fetchAccount.pageIndex = pages;
        this.fetchAccount.pageSize = pageSize;
        this.$store.dispatch(GET_ALL_SYSTEM_ACTIVITIES, this.fetchAccount);
      }
    },
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    getUpdatedDeposits(value) {
      this.fetchAccount.pageSize = value;
      this.getActivities();
    },
    changePageIndex(value, pageSize) {
      this.changeCurrentPage(value, pageSize);
    },
    downloadCSVFile() {
      const jsonData = this.allActivities.data.map((a) => ({
        savingsAccountName: a.savingsAccountName,
        accountFirstName: a.accountFirstName,
        accountLastName: a.accountLastName,
        accountStatus: a.accountStatus,
        balance: a.balance,
      }));

      let blob = new Blob([Papa.unparse(jsonData)], {
        type: "text/csv;charset=utf-8;",
      });
      let link = document.createElement("a");
      let url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "deposit-account.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    viewRoute(data) {
      if(data) return this.$router.push({ path: `/customer/${data}` });
      this.$router.back();
    },
  },
  computed: {
    ...mapState({
      allActivities: (state) => state.activity.allActivities,
      allActivitiesExports: (state) => state.activity.allActivitiesExport,
      loadingActivities: (state) => state.activity.loadingActivities,
      user: (state) => state.auth.user,
    }),
    userCanExportToExcel() {
      const user = this.user.scopes.find(item => item.scopeName === Role.Documents);
      const { permissionsList } = user;
      return permissionsList.CanExportToExcel;
    },

    userCanExportToPDF() {
      const user = this.user.scopes.find(item => item.scopeName === Role.Documents);
      const { permissionsList } = user;
      return permissionsList.CanDownloadDocument;
    },
    download() {
      let data = [];
      if(this.allActivitiesExports && this.allActivitiesExports.data) {
        this.allActivitiesExports.data.forEach((item) => {
          let new_obj = {};
          new_obj.date = moment(item.creationDate).format("Do-MMM-YYYY");;
          new_obj.user = item.user;
          new_obj.itemName = item.affectedItemName;
          new_obj.itemID = item.affectedItemId;
          new_obj.action = item.action;
          new_obj.customerName = item.affectedCustomerName;

          data.push(new_obj);
        });
      }
      return data;
    },
  },
  created() {
    this.getActivities();
  },
  filters: {
    moment: function (date) {
      return date != null ? moment(date).format("Do-MMM-YYYY") : "";
    },
  },
};
</script>

<style scoped>
.button--sm.button--cancel span {
  font-size: 1rem;
}
.button--cancel,
.button--grey.text--black {
  font-size: 1rem;
}
.cursor {
  cursor: pointer;
}
</style>
